import * as Sentry from "@sentry/vue";
import axios, { AxiosError } from "axios";
import { ApiError } from "openapi-typescript-fetch";
import { defineStore } from "pinia";
import * as v from "valibot";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Urls from "@/autogen/urls";
import { entries } from "@/functional_utils";
import api from "@/lib/api/internal-base";
import { showToast } from "@/lib/toasts";
import { Feature, requiredPlan } from "@/screens/Billing/lib";
import {
  LocalStorageKey,
  safeLocalStorageGetItem,
} from "@/store/local_storage";
import { useLocalStorageBackedRef } from "@/store/stores/base";
import { Account, Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

declare let account: Account | null;

declare const DEMO_USERNAME: string;

export const PERMISSION_DENIED_ERROR_MESSAGE =
  "You don't have permission to access this resource.";

const ErrorMessage = v.union([
  v.pipe(
    v.object({ detail: v.string() }),
    v.transform((error) => error.detail)
  ),
  v.pipe(
    v.object({
      username: v.tuple([
        v.literal("newsletter with this username already exists."),
      ]),
    }),
    v.transform(() => `A newsletter with this username already exists.`)
  ),
  v.pipe(
    v.array(v.string()),
    v.transform((messages) => messages.join(", "))
  ),
  v.pipe(
    v.record(v.string(), v.array(v.string())),
    v.check((object) => Object.keys(object).length > 0),
    v.transform((object) => {
      const [key, value] = entries(object)[0];
      if (value[0] === "This field may not be blank.") {
        return `\`${key}\` may not be blank.`;
      }
    })
  ),
]);

export const constructErrorMessage = (err: AxiosError<any>): string => {
  // If it's a cancellation, nack.
  if (axios.isCancel(err)) {
    return "ignore";
  }
  if (!err.response) {
    return "An error occurred while communicating with the server.";
  }
  const { data, status } = err.response;
  if (status === 403) {
    return PERMISSION_DENIED_ERROR_MESSAGE;
  }
  if (status === 400) {
    if (data.url) {
      return data.url;
    } else if (data && typeof data === "object") {
      const message = v.safeParse(ErrorMessage, data);

      if (message.success && message.output !== undefined) {
        return message.output;
      }

      return (
        "Buttondown was unable to handle your inputs: " + JSON.stringify(data)
      );
    } else {
      return "Please double check your inputs to make sure everything looks valid.";
    }
  }
  return "An unexpected error occured.";
};

const handleError = (
  err: AxiosError<any>,
  setPaidFeatureDialog: (arg0: boolean) => void
) => {
  const message = constructErrorMessage(err);
  if (message === "ignore") {
    return;
  } else if (message.includes("allow for custom domains.")) {
    setPaidFeatureDialog(true);
  } else {
    showToast({
      type: "error",
      title: message,
    });
  }
  throw err;
};

const handleApiError = (err: ApiError) => {
  let errorMessage: string | string[] | undefined;

  try {
    errorMessage = JSON.parse(err.data.detail.replace(/'/g, '"'));
  } catch {
    errorMessage = err.data.detail;
  }

  if (Array.isArray(errorMessage)) {
    errorMessage.forEach((detail) => {
      showToast({
        type: "error",
        title: detail,
      });
    });
  } else if (typeof errorMessage === "string") {
    showToast({
      type: "error",
      title: errorMessage,
    });
  } else {
    showToast({
      type: "error",
      title: `An error occured while communicating with the server`,
      message: `Please try again later.`,
    });
  }
};

const fetchActiveNewsletter = (account: Account): Newsletter | null => {
  if (!account.permissions) {
    return null;
  }
  const activeNewsletterId = safeLocalStorageGetItem(
    LocalStorageKey.ACTIVE_NEWSLETTER_ID
  );
  if (activeNewsletterId) {
    const matchingNewsletter = account.permissions.find(
      (permission) => permission.newsletter.id === activeNewsletterId
    );
    if (matchingNewsletter) {
      return matchingNewsletter.newsletter;
    }
  }
  return account.permissions ? account.permissions[0].newsletter : null;
};

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const useStore = defineStore(
  "main",
  () => {
    const router = useRouter();
    const activeAccount = ref<Account | null>(account);
    const activeNewsletter = ref<Newsletter | null>(
      account ? fetchActiveNewsletter(account) : null
    );
    const switchNewsletter = (n: Newsletter) => {
      activeNewsletter.value = n;
      localStorage.setItem(LocalStorageKey.ACTIVE_NEWSLETTER_ID, n.id);
      const currentRoute = router.currentRoute.value;
      if (UUID_REGEX.test(currentRoute.fullPath)) {
        router.push({
          name: "/emails",
        });
      }
    };
    const updateAccount = async (account: Account) => {
      try {
        const response = await api.path("/account/{id}").method("put").create()(
          { ...account }
        );

        activeAccount.value = response.data;

        showToast({
          type: "success",
          title: `Settings updated`,
        });
      } catch (err: any) {
        handleError(err, setPaidFeatureDialog);
      }
    };

    const updatingNewsletterAbortController = ref<AbortController | null>(null);
    const updatingNewsletter = ref<boolean>(false);
    const updateNewsletter = async (newsletter: Newsletter) => {
      try {
        updatingNewsletterAbortController.value?.abort(
          "Aborting request because newsletter has changed."
        );

        const source = new AbortController();
        updatingNewsletterAbortController.value = source;
        updatingNewsletter.value = true;
        const response = await api
          .path("/newsletters/{id}")
          .method("put")
          .create()({ ...newsletter }, { signal: source.signal });
        updatingNewsletter.value = false;

        activeNewsletter.value = {
          ...response.data,
          //@ts-ignore
          api_key: activeNewsletter.value?.api_key,
        };

        if (activeAccount.value) {
          activeAccount.value.permissions = activeAccount.value.permissions.map(
            (p: Permission) => {
              if (p.newsletter.id === response.data.id) {
                return {
                  ...p,
                  newsletter: response.data,
                };
              } else {
                return p;
              }
            }
          );
        }

        showToast({
          type: "success",
          title: `Settings updated`,
        });
      } catch (err) {
        handleApiError(err as ApiError);
      }
    };

    const deleteAccount = async () => {
      await api.path("/account/{id}").method("delete").create()({
        id: activeAccount.value?.id || "",
      });

      showToast({
        type: "error",
        title: `Account deleted`,
        message: `Have a good day!`,
      });

      window.location.href = "/";
    };

    const deleteNewsletter = async (newsletter: Newsletter) => {
      await api.path("/newsletters/{id}").method("delete").create()({
        id: newsletter.id,
      });
      window.location.href = "/settings";
    };

    const showingDialog = ref(false);
    const showingDrawer = ref<"no" | "yes" | "takeover">("no");
    const showingSidebar = useLocalStorageBackedRef(
      "showingSidebar",
      true,
      (value) => (value === true ? "true" : "false"),
      (value) => value === "true"
    );
    const showingPaidFeatureDialog = ref(false);

    const toggleDialog = (value: boolean) => {
      showingDialog.value = value;
    };
    const toggleSidebar = () => {
      showingSidebar.value = !showingSidebar.value;
    };
    const toggleDrawer = (value: "no" | "yes" | "takeover") => {
      showingDrawer.value = value;
    };
    const setPaidFeatureDialog = (value: boolean) => {
      showingPaidFeatureDialog.value = value;
    };

    const currentPermissions = computed(() => {
      return (
        activeAccount.value?.permissions.find(
          (p: Permission) => p.newsletter.id === activeNewsletter.value?.id
        )?.permissions || {}
      );
    });

    const logout = async () => {
      await axios.delete(Urls["authenticate"](), {});
      window.location.href = "/";
    };

    const login = async (payload: {
      username: string;
      password: string;
      code?: string;
    }) => {
      try {
        const response = await fetch(Urls["authenticate"](), {
          method: "POST",
          headers: {
            Authorization: `Basic ${btoa(JSON.stringify(payload))}`,
            "Content-Type": "application/json",
          },
        });

        const user = await response.json();

        if (user.detail) {
          showToast({
            type: "error",
            title: user.detail,
          });

          return;
        }

        const accountResponse = await fetch(
          Urls["internal-api:get_account"](user.account)
        );

        const accountData = await accountResponse.json();
        activeAccount.value = accountData;
        activeNewsletter.value = fetchActiveNewsletter(accountData);
        // Set the scope in Sentry.
        Sentry.setUser({
          username: accountData.username,
          email: accountData.email_address,
          id: accountData.id,
        });

        showToast({
          type: "success",
          title: `Logged in successfully`,
        });
      } catch (error) {
        handleApiError(error as ApiError);
      }
    };

    const createAccount = async (payload: {
      username: string;
      password: string;
      email: string;
      extantNewsletterUsername?: string;
    }) => {
      await axios.post(Urls["register"](), payload);
      await login({
        username: payload.username,
        password: payload.password,
      });
    };

    watch(
      activeNewsletter,
      () => {
        const tintColor = activeNewsletter.value?.tint_color || "#0069FF";
        document.documentElement.style.setProperty("--tint-color", tintColor);
      },
      {
        immediate: true,
      }
    );

    const calculatePlanRequirement = (feature: Feature) => {
      if (activeAccount.value === null) {
        return null;
      }

      // Janky, but easier than bringing in the entire owning account.
      // This assumes that the two accounts have the same billing type, which seems very safe.
      const accountWithOwningAccountBillingPlan = {
        ...activeAccount.value,
        billing_plan:
          activeNewsletter.value?.owning_account_billing_plan ??
          activeAccount.value.billing_plan,
        features:
          activeNewsletter.value?.owning_account_features ??
          activeAccount.value.features,
      };
      return requiredPlan(
        accountWithOwningAccountBillingPlan as Account,
        feature
      );
    };
    const isDemo = computed(() => DEMO_USERNAME !== "");

    return {
      showingSidebar,
      showingDialog,
      toggleDialog,
      deleteAccount,
      currentPermissions,
      toggleSidebar,
      showingPaidFeatureDialog,
      isDemo,
      setPaidFeatureDialog,
      newsletter: activeNewsletter,
      account: activeAccount,
      switchNewsletter,
      updateAccount,
      deleteNewsletter,
      updateNewsletter,
      logout,
      login,
      createAccount,
      toggleDrawer,
      showingDrawer,
      calculatePlanRequirement,
      updatingNewsletter,
    };
  },
  {
    broadcast: {
      // Imagine two tabs open, one in a route with a drawer and one without. Without this flag, the latter route
      // would be offset by the drawer's width (due to the sizing stuff we handle in `App.vue`.)
      omit: ["showingDrawer", "showingSidebar"],
    },
  }
);
